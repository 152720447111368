<template>
    <div>
        <CTabs variant="tabs" class="nav-tabs-boxed">
            <CTab :title="descPlural">
                <CartaoCredito/>
            </CTab>
        </CTabs>
    </div>

</template>
<script>
  import CartaoCredito from './CartaoCredito/CartaoCredito';
  import {loadDescs} from '../../helpers/utils';

  export default {
    name: 'pagamento',
    components: {
      CartaoCredito,
    },
    data() {
      return {
        descSingular: '',
        descPlural: '',
      };
    },
    methods: {
      changeTab(tabName) {
        this.activeTab = tabName;
      },
    },

    beforeMount() {
      const {descSingular, descPlural} = loadDescs('ADMIN_PAGAMENTO');
      this.descSingular = descSingular;
      this.descPlural = descPlural;
    },
  };
</script>
