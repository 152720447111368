<template>
  <div>
    <Edit
      :modoDeEdicao="modoDeEdicao"
      :adquirente="currentAdquirente"
      :cobrancasDisponiveis="cobrancasDisponiveis"
      :gatewaysDisponiveis="gatewaysDisponiveis"
      :ordensDisponiveis="ordensDisponiveis"
      :salvar="salvar"
      :close="closeEdit"
      :resetEdit="resetEdit"
    ></Edit>

    <div class="filters row pull-right">
      <button class="button button-success" v-on:click="openEdit()">
        Configurar Novo Pagamento
      </button>
    </div>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Gateway</th>
            <th>Ambiente</th>
            <th>Ordem</th>
            <th>Cobrança</th>
            <th>Processar Pagamento</th>
            <th>Certificados</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="adquirente in adquirentes" v-bind:key="adquirente.id">
            <td>{{ adquirente.nome }}</td>
            <td>
              <span class="label primary">{{ adquirente.gateway }}</span>
            </td>
            <td>{{ adquirente.ambiente }}</td>
            <td>
              <span class="label success">{{ adquirente.ordem }}</span>
            </td>
            <td>{{ adquirente.cobranca_id }}</td>
            <td>
              <span class="label success"
                >{{ adquirente.processa_pgto }} ETAPA</span
              >
            </td>
            <td v-if="adquirente.gateway === 'PIX_SICREDI'">
              <span class="label success">Sim</span>
            </td>
            <td v-else>
              <span class="label error">Não</span>
            </td>
            
            <td class="actions">
              <button
                type="button"
                name="button"
                v-on:click="deleteConfirm(adquirente.id)"
                class="button button-error"
              >
                <fa-icon icon="trash-alt" />
              </button>
              <button
                class="button button-primary"
                v-on:click="openEdit(adquirente.id)"
              >
                <fa-icon icon="pencil-alt" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { upload, get } from "../../../../helpers/apiRequest";
import Edit from "./Edit";

const INITIAL_ADQUIRENTE = {
  id: "",
  nome: "NOVA ADQUIRENTE",
  ambiente: "HOMOLOGACAO",
  gateway: "CIELO",
  ordem: 1,
  cobranca_id: "BK",
  merchant_key: "",
  merchant_id: "",
  processa_pgto: 2,
  instrucoes: "",
  filiais: [],
  certificados: [],
  certificados_link: [],
};

export default {
  name: "adquirentes",
  props: [
    "adquirentes",
    "cobrancasDisponiveis",
    "gatewaysDisponiveis",
    "salvarAdquirente",
    "removerAdquirente",
    "resetStatus",
    "error",
  ],
  components: {
    Edit,
  },
  data() {
    return {
      currentAdquirente: { ...INITIAL_ADQUIRENTE },
      modoDeEdicao: "EDITAR",
    };
  },
  computed: {
    ordensDisponiveis() {
      const ordens = this.adquirentes.map((_, index) => index + 1);
      return this.modoDeEdicao === "EDITAR"
        ? ordens
        : ordens.concat(this.adquirentes.length + 1);
    },
  },
  methods: {
    openEdit(id = "") {
      this.modoDeEdicao = id ? "EDITAR" : "CRIAR";
      this.currentAdquirente =
        this.modoDeEdicao === "EDITAR"
          ? { ...this.adquirentes.find((adquirente) => adquirente.id === id) }
          : { ...INITIAL_ADQUIRENTE };

      this.$modal.show("editAdquirente");
    },
    closeEdit() {
      this.$modal.hide("editAdquirente");
    },
    resetEdit() {
      this.modoDeEdicao = "EDITAR";
      this.certificados = [];
      this.currentAdquirente = { ...INITIAL_ADQUIRENTE };
      this.resetStatus();
    },
    salvar(modo, adquirente) {
      this.salvarAdquirente(modo, adquirente, () => {
        this.closeEdit();
        this.resetEdit();
      });
    },
    deleteConfirm(id) {
      this.$swal({
        title: "Confirma Remover?",
        text: "Essa ação não poderá ser desfeita",
        showCancelButton: true,
        icon: "warning",
        confirmButtonText: "Excluir",
        cancelButtonText: "Cancelar",
      }).then((res) => {
        if (res.value) {
          this.removerAdquirente(id);
        }
      });
    },
  },
};
</script>
