<template>
  <div>
    <!-- status -->
    <Loading :isActive="loading"></Loading>
    <div v-if="error" class="bdti-alert bdti-alert-danger">{{ error.message }}</div>

    <!-- componentes -->
    <Adquirentes
      :adquirentes="adquirentes"
      :cobrancasDisponiveis="cobrancasDisponiveis"
      :gatewaysDisponiveis="gatewaysDisponiveis"
      :salvarAdquirente="salvarAdquirente"
      :removerAdquirente="removerAdquirente"
      :error="adquirenteEditError"
      :resetStatus="resetStatus"
    />
  </div>
</template>
<script>
import clone from "just-clone";
import { get, post, put, del } from "../../../helpers/apiRequest";
import Adquirentes from "./Adquirentes/Adquirentes";
import jsonFormData from 'json-form-data';
import { isFile } from "@babel/types";

export default {
  name: "carta-credito",
  components: {
    Adquirentes
  },
  data() {
    return {
      // geral
      loading: false,
      error: null,
      // adquirentes
      adquirentes: [],
      adquirenteEditError: null,
      cobrancasDisponiveis: [],
      gatewaysDisponiveis: []
      // ...
    };
  },
  methods: {
    loadAdquirentes() {
      this.loading = true;
      get(`/admin/adquirentes`)
        .then(json => {
          this.adquirentes = json;
        })
        .catch(err => {
          this.error = error.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadCobrancasDisponiveis() {
      this.loading = true;
      get(`/admin/adquirentes/cobrancas-cartao`)
        .then(json => {
          this.cobrancasDisponiveis = json;
        })
        .catch(err => {
          this.error = error.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadGatewaysDisponiveis() {
      this.loading = true;
      get(`/admin/adquirentes/gateways`)
        .then(json => {
          this.gatewaysDisponiveis = json;
        })
        .catch(err => {
          this.error = error.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    salvarAdquirente(modo = "EDITAR", adquirente, onSuccess) {
      if (["CRIAR", "EDITAR"].indexOf(modo) < 0) {
        return (this.error = { message: "Modo de edição inválido." });
      }

      const options = {
        initialFormData: new FormData(),
        showLeafArrayIndexes: true
      };

      let apiMethod;

      const formDataItems = jsonFormData(adquirente, options);
      if (modo === "CRIAR") {
        apiMethod = post(`/admin/adquirentes`, formDataItems, {isFile: true});
      }
      if (modo === "EDITAR") {
        apiMethod = post(`/admin/adquirentes/${adquirente.id}`, formDataItems, {isFile: true});
      }

      this.loading = true;

      apiMethod
        .then(json => {
          if (modo === "CRIAR") {
            this.$swal({
              title: "Sucesso!",
              text: "Adquirente cadastrado com sucesso!",
              icon: "success",
            });
          }

          if (modo === "EDITAR") {
            this.$swal({
              title: "Sucesso!",
              text: "Adquirente alterado com sucesso!",
              icon: "success",
            });
          }

          if (onSuccess && typeof onSuccess === "function") {
            onSuccess(json);
          }
          this.loadAdquirentes();
        })
        .catch(err => {
          this.$swal({
            title: "Falha ao cadastrar!",
            text: err.message,
            showCancelButton: true,
            cancelButtonText: "Fechar",
            showConfirmButton: false,
            icon: "error",
          });
          this.adquirenteEditError = err.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    removerAdquirente(id) {
      this.loading = true;
      del(`/admin/adquirentes/${id}`)
        .then(json => {
          this.$swal({
            title: "Sucesso!",
            text: "Adquirente deletado com sucesso!",
            icon: "success"
          });
          this.loading = false;
          this.loadAdquirentes();
        })
        .catch(err => {
          this.$swal({
            title: "Falha ao excluir!",
            text: err.message,
            showCancelButton: true,
            cancelButtonText: "Fechar",
            showConfirmButton: false,
            icon: "error",
          });
          this.error = err;
          this.loading = false;
        });
    },

    resetStatus() {
      this.loading = null;
      this.error = null;
      this.adquirenteEditError = null;
    }
  },
  beforeMount() {
    this.loadAdquirentes();
    this.loadCobrancasDisponiveis();
    this.loadGatewaysDisponiveis();
  }
};
</script>